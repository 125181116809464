import React from 'react';

import { AuditMatterTerminated } from '../auditMatterTerminated';
import { AuditorAuditMatterTransferredFrom } from '../auditorAuditMatterTransferredFrom';
import { AuditorAuditMatterTransferredTo } from '../auditorAuditMatterTransferredTo';
import { AuditorOOO } from '../auditorOutOfOffice';
import { AuditorReplyToCorrespondence } from '../auditorReplyToCorrespondence';
import { AuditorToCustomerMessageReceived } from '../auditorToCustomerMessageReceived';
import { CloseCorrespondence } from '../closeCorrespondence';
import { ContactRegistrationAlert } from '../contactRegistration';
import { CustomerAuditMatterTransferred } from '../customerAuditMatterTransferred';
import { CustomerCorrespondenceReadReceipt } from '../customerCorrespondenceReadReceipt';
import { CustomerCorrespondenceResponseReceived } from '../customerCorrespondenceResponseReceived';
import { CustomerDocumentReadReceipt } from '../customerDocumentReadReceipt';
import { CustomerOnboardingEmailSent } from '../customerOnboardingEmailSent';
import { CustomerToAuditorMessageReceived } from '../customerToAuditorMessageReceived';
import { CustomerToCustomerMessageReceived } from '../customerToCustomerMessageReceived';
import { NewCorrespondence } from '../newCorrespondence';
import { NewMatterAlert } from '../newMatter';
import { NewMessage } from '../newMessage';
import { MatterNotificationContent } from '../types';

type UpdateStatusFunction = (alertId: string) => void;
type UpdateMatterContextFunction = (
  matterIdOrThreadId: string,
  threadId?: string,
) => void;

type AlertComponentProps<T> = {
  alertId: string;
  data: T;
  createdDate: string;
  read: boolean;
  updateStatus: UpdateStatusFunction;
  updateMatterContext?: UpdateMatterContextFunction;
};

type AlertComponent = React.FC<AlertComponentProps<any>>;

const alertComponents: Record<string, AlertComponent> = {
  NEW_MATTER: NewMatterAlert,
  CONTACT_REGISTRATION: ContactRegistrationAlert,
  CUSTOMER_ONBOARDING_EMAIL_SENT: CustomerOnboardingEmailSent,
  CUSTOMER_DOCUMENT_READ: CustomerDocumentReadReceipt,
  CUSTOMER_CORRESPONDENCE_READ: CustomerCorrespondenceReadReceipt,
  NEW_CORRESPONDENCE: NewCorrespondence,
  CUSTOMER_RESPONSE_RECEIVED: CustomerCorrespondenceResponseReceived,
  AUDITOR_REPLY_TO_CORRESPONDENCE: AuditorReplyToCorrespondence,
  CLOSE_CORRESPONDENCE: CloseCorrespondence,
  NEW_MESSAGE: NewMessage,
  AUDITOR_TO_CUSTOMER_MESSAGE_RECEIVED: AuditorToCustomerMessageReceived,
  CUSTOMER_TO_AUDITOR_MESSAGE_RECEIVED: CustomerToAuditorMessageReceived,
  CUSTOMER_TO_CUSTOMER_MESSAGE_RECEIVED: CustomerToCustomerMessageReceived,
  AUDITOR_MATTER_TRANSFERRED_FROM: AuditorAuditMatterTransferredFrom,
  AUDITOR_MATTER_TRANSFERRED_TO: AuditorAuditMatterTransferredTo,
  CUSTOMER_MATTER_TRANSFERRED: CustomerAuditMatterTransferred,
  AUDITOR_MATTER_TERMINATED: AuditMatterTerminated,
  AUDITOR_OUT_OF_OFFICE: AuditorOOO,
};

type NotificationItemProps = {
  notification: MatterNotificationContent;
  updateStatus: UpdateStatusFunction;
  updateMatterContext?: UpdateMatterContextFunction;
};

const Alert: React.FC<NotificationItemProps> = ({
  notification,
  updateStatus,
  updateMatterContext,
}: NotificationItemProps) => {
  const { messageData, alertType, createdDate, read, id } = notification;
  const AlertComponent = alertComponents[alertType];

  if (!AlertComponent) return null;

  return (
    <AlertComponent
      alertId={id}
      data={messageData}
      createdDate={createdDate}
      read={read}
      updateStatus={updateStatus}
      updateMatterContext={updateMatterContext}
    />
  );
};

export default Alert;
