import { Button } from '@snsw/react-component-library';
import { Accordion } from '@snsw/react-component-library/build/Components';
import { colours, pxToRem, spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  section {
    padding: 0;
  }
  span {
    span:last-child {
      width: 600px;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${spacing.lg};
`;

export const StyledFileUploadContainer = styled.div`
  padding: ${spacing.lg};
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  td {
    padding: ${spacing.xs};
  }
  .gebolZ > div:first-of-type {
    overflow-x: unset;
  }

  table tbody tr td:first-child {
    width: 40%;
  }

  .entity div:first-child {
    margin: 0;
  }
`;

export const NoStyledButton = styled.button<{
  hasError?: boolean;
}>`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 100%;
  border: ${(props) =>
    props.hasError ? `2px solid ${colours.borders.error}` : 'none'};
  height: ${pxToRem(48)};
  width: 100%;
`;

export const StyledErrorText = styled.span`
  color: ${colours.borders.error};
`;
