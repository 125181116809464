import * as t from 'io-ts';
import { CorrespondenceRequestBody } from 'src/hooks/types';
import { tOptional } from 'src/types';

export type DocumentItem = {
  name: string;
  id: string;
  s3Key: string;
};

export type SubmitAuditCommencementDocumentsInitValues = {
  customer: string;
  recipient: string;
  dueDate: string;
  message: string;
  subject: string;
  selectedDocuments: string[];
};

export const DocumentsCodec = t.type({
  id: t.string,
});

export const notificationsCodec = t.type({
  notificationRefId: t.string,
});

export const clientContactsCodec = t.type({
  id: t.string,
  clientId: t.number,
  contactId: t.number,
  contactEmail: t.string,
});

export const Client = t.type({
  clientId: t.number,
});

export const SubmitAuditCommencementDocumentsCodec = t.type({
  id: t.string,
  sentDate: tOptional(t.string),
  initiatedBy: t.string,
  dueDate: tOptional(t.string),
  subject: tOptional(t.string),
  messageBody: tOptional(t.string),
  documents: tOptional(t.array(DocumentsCodec)),
  portalContacts: tOptional(t.array(clientContactsCodec)),
  clients: tOptional(t.array(Client)),
});

export type SubmitAuditCommencementDocuments = t.TypeOf<
  typeof SubmitAuditCommencementDocumentsCodec
>;

export type ClientContacts = t.TypeOf<typeof clientContactsCodec>;
export type UploadedDocuments = t.TypeOf<typeof DocumentsCodec>;

export enum RequestType {
  Add,
  Update,
}

export enum ModalType {
  Draft = 'Draft',
  Open = 'Open',
}

export type AuditCommencementRequestBody = {
  documents: {
    id: string;
  }[];
  corroType: string;
  status: ModalType;
  dueDate: string;
  subject: string;
  messageBody: string;
  portalContacts: {
    id: string;
  }[];
};

export type OnConfirmType = (args: {
  matterUId: string;
  payload: CorrespondenceRequestBody;
  requestType?: RequestType;
  threadId?: string;
  correspondenceId?: string;
  saveAs?: 'draft';
  isCustomer?: boolean;
  isReply?: boolean;
}) => Promise<void>;
