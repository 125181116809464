const contents = {
  'matter.correspondence.auditCommencementDocuments.auditor.heading':
    'Audit commencement documents',
  'matter.correspondence.requestForInformation.auditor.heading':
    'Request for Information',
  'matter.correspondence.requestForInformation.customer.heading':
    'Submit response to request for information',
  'matter.correspondence.preliminaryFindings.auditor.heading':
    'Preliminary Findings',
  'matter.correspondence.preliminaryFindings.customer.heading':
    'Submit response to preliminary findings',
  'matter.correspondence.finalisationAdvice.auditor.heading':
    'Finalisation Advice',
  'matter.correspondence.finalisationAdvice.customer.heading':
    'Submit response to finalisation advice',
  'matter.correspondence.message':
    'All fields must be completed, unless marked optional.',
  'matter.correspondence.document.alert.message':
    'Please be aware that all authorised contacts in this audit matter can view ALL portal content and documents',
  'matter.correspondence.documents.table.header.fileName': 'Filename (size)',
  'matter.correspondence.documents.table.header.editProperties':
    'Edit properties',
  'matter.correspondence.documents.table.header.remove': 'Remove',
  'matter.correspondence.documents.table.header.remove.tooltip.text':
    'Uploaded document(s) can be removed before the final submission.',
  'matter.correspondence.message.helpText.text':
    'Maximum 1000 characters including spaces. {remChar} character(s) remaining',
  'matters.correspondence.dueDate.tooltip.text':
    'This is the due date by when customer is expected to respond',
  'matters.correspondence.customer.tooltip.text':
    'This is the customer for which this correspondence relates to',
  'matters.correspondence.message.helpMessage.text':
    'Maximum 1000 characters including spaces. {remChar} character(s) remaining',
  'matter.correspondence.fileUpload.heading': 'Documents',
  'matter.correspondence.fileUpload.sub.heading': 'Uploaded documents',
  'correspondence.correspondence.saveAsDraft.modal.title': 'Save as draft',
  'correspondence.correspondence.customer.saveAsDraft.modal.description':
    'A draft for {corroType} response will be saved in correspondence. Until the final submission is made, your response shall not be viewed by the case officer.',
  'correspondence.correspondence.auditor.saveAsDraft.modal.description':
    'A draft will be saved in correspondence.',
  'correspondence.correspondence.auditor.confirm.modal.description':
    'Confirm you want to send the following',
  'correspondence.correspondence.customer.confirm.modal.description':
    'The following response will be sent:',
  'correspondence.correspondence.customer.confirm.modal.entityNames.label':
    'Entity name(s) ',
  'correspondence.correspondence.customer.confirm.modal.recipientNames.label':
    'Recipient name',
  'correspondence.correspondence.customer.confirm.modal.dueDate.label':
    'Due date',
  'correspondence.correspondence.auditor.confirm.modal.messageSubject.label':
    'Message subject',
  'correspondence.correspondence.auditor.confirm.modal.message.label':
    'Message',
  'correspondence.correspondence.auditor.confirm.modal.documents.label':
    'Documents',
  'correspondence.correspondence.customer.confirm.modal.recipients.label':
    'Recipient(s)',
  'correspondence.correspondence.customer.confirm.modal.documents.label':
    'Document(s) attached',
  'correspondence.correspondence.auditor.auditCommencementDocuments.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.auditor.requestForInformation.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.auditor.requestForInformation.response.confirm.modal.title':
    'Confirm and send request for information',
  'correspondence.correspondence.customer.requestForInformation.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.customer.requestForInformation.response.confirm.modal.title':
    'Send request for information',
  'correspondence.correspondence.auditor.preliminaryFindings.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.auditor.preliminaryFindings.response.confirm.modal.title':
    'Confirm and send preliminary findings',
  'correspondence.correspondence.customer.preliminaryFindings.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.customer.preliminaryFindings.response.confirm.modal.title':
    'Send preliminary findings response',
  'correspondence.correspondence.auditor.finalisationAdvice.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.auditor.finalisationAdvice.response.confirm.modal.title':
    'Confirm and send finalisation advice',
  'correspondence.correspondence.customer.finalisationAdvice.reply.confirm.modal.title':
    'Confirm and send response',
  'correspondence.correspondence.customer.finalisationAdvice.response.confirm.modal.title':
    'Send finalisation advice response',
};

export default contents;
