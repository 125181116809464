import termsOfUseContent from './termsOfUse/content';

const content = {
  ...termsOfUseContent,
  'onboarding.heading.main': 'Customer Compliance Portal onboarding',
  'onboarding.privacyCollectionNotice.heading': 'Privacy Collection Notice',
  'onboarding.privacyCollectionNotice.text':
    '<p>Revenue NSW is collecting your information to administer the <a href="https://legislation.nsw.gov.au/view/html/inforce/current/act-2007-021" target="_blank" rel="noreferrer">Payroll Tax Act 2007.</a></p>' +
    '<p>Revenue NSW gives priority to protecting the privacy of information we receive. We do this by handling information in a responsible manner and in accordance with the <a href="https://legislation.nsw.gov.au/view/html/inforce/current/act-1998-133" target="_blank" rel="noreferrer">Privacy and Personal Information Protection Act 1998</a> and <a href="https://legislation.nsw.gov.au/view/html/inforce/current/act-1996-097" target="_blank" rel="noreferrer">Taxation Administration Act 1996.</a></p>' +
    '<p>Revenue NSW may disclose your information for law enforcement, for legal proceedings arising out of a taxation law, to permitted statutory or regulatory bodies, for reciprocal taxation law administration with another Australian jurisdiction, or any other reason contained within <a href="https://legislation.nsw.gov.au/view/html/inforce/current/act-1996-097#pt.9-div.3" target="_blank" rel="noreferrer">Part 9, Division 3 of the Taxation Administration Act 1996,</a> or as permitted by law.</p>' +
    '<p>Revenue NSW does not require you to disclose tax file numbers for Payroll Tax purposes. If you submit information to us that includes tax file numbers and data that was not requested, you should redact this information where possible.</p>' +
    '<p>For more privacy information regarding the collection of information about an individual, please refer to the <a href="https://www.revenue.nsw.gov.au/privacy" target="_blank" rel="noreferrer">Revenue NSW Policy.</a></p>',
};

export default content;
