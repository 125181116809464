import { RecipientData } from 'src/hooks/types';
import {
  buildEntities,
  buildRecipients,
  getExistingCustomers,
} from 'src/screens/common/matters/tabs/common/matterCorrespondence/helper';

import { CorrespondenceTemplatesResponse } from '../correspondenceTemplate/types';

import {
  ClientContacts,
  ModalType,
  SubmitAuditCommencementDocuments,
  SubmitAuditCommencementDocumentsInitValues,
  UploadedDocuments,
} from './types';

export const getClientName = (
  clientOptions: {
    text: string;
    value: string;
  }[],
  selectedClientId: string,
) => {
  return (
    clientOptions.find((option) => String(option.value) === selectedClientId)
      ?.text || ''
  );
};

export const buildDocuments = (selectedDocuments: string[]) => {
  return selectedDocuments.map((documentId) => {
    return { id: documentId };
  });
};

export const getRequestBody = (
  auditCommencementValues: SubmitAuditCommencementDocumentsInitValues,
  modalType: ModalType,
) => {
  const { dueDate, subject, message, recipient, selectedDocuments, customer } =
    auditCommencementValues;

  return {
    dueDate,
    subject,
    messageBody: message,
    corroType: 'AUDIT COMMENCEMENT DOCUMENTS',
    status: modalType,
    ...(recipient && { portalContacts: buildRecipients(recipient) }),
    ...(customer && { clients: buildEntities(customer) }),
    documents: buildDocuments(selectedDocuments),
  };
};

const getRecipientsAsString = (clientContacts?: ClientContacts[] | null) => {
  const recipients = clientContacts?.map(({ id }) => id);
  const uniqueRecipients = [...new Set(recipients)];

  return uniqueRecipients.join(',');
};

const getSelectedDocumentIds = (documents: UploadedDocuments[]) =>
  documents.map(({ id }) => id);

export const getInitValuesFromResponse = (
  data: SubmitAuditCommencementDocuments,
): SubmitAuditCommencementDocumentsInitValues => {
  const {
    dueDate,
    subject,
    messageBody: message,
    portalContacts,
    documents,
    clients,
  } = data;
  const customers = getExistingCustomers(clients);
  const recipients = getRecipientsAsString(portalContacts);
  const selectedDocuments =
    (documents && getSelectedDocumentIds(documents)) ?? [];
  return {
    dueDate: dueDate || '',
    subject: subject || '',
    message: message || '',
    customer: customers?.join() || '',
    recipient: recipients,
    selectedDocuments,
  };
};

export const getMessageTemplateOptions = (
  correspondenceTemplatesResponse?: CorrespondenceTemplatesResponse,
) => {
  const options = [];
  if (!correspondenceTemplatesResponse) {
    return options;
  }

  return correspondenceTemplatesResponse.map(({ name }) => ({
    text: name,
    value: name.toLocaleLowerCase().replace(/ /g, '_'),
  }));
};

export const getMessagePlaceholder = (
  messageTemplate: string,
  correspondenceTemplatesResponse?: CorrespondenceTemplatesResponse,
) => {
  if (!correspondenceTemplatesResponse) {
    return undefined;
  }

  return (
    correspondenceTemplatesResponse.find(({ name }) => {
      return name.toLocaleLowerCase().replace(/ /g, '_') === messageTemplate;
    })?.data || undefined
  );
};

export const getRecipientNames = (
  values: SubmitAuditCommencementDocumentsInitValues,
  recipientData?: RecipientData[] | null,
) => {
  if (!recipientData) {
    return [];
  }
  const { recipient } = values;
  const recipients = recipient.replace(/^,/, '').split(',');
  return recipientData
    .filter(({ id }) => recipients.includes(id))
    .map(({ contactName }) => contactName);
};
