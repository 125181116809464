import environments from 'src/environments';
import { useUserContext } from 'src/hooks';
import { UserLoginType } from 'src/types';

import {
  FEEDBACK_ASSIST_INLINE_SCRIPT_ID,
  FEEDBACK_ASSIST_SCRIPT_ID,
} from './constants';
import useScriptWithInline from './useScriptWithInline';

const FeedbackAssist = () => {
  const userContext = useUserContext();
  const isStaff = userContext?.userType === UserLoginType.Staff;

  useScriptWithInline(
    FEEDBACK_ASSIST_SCRIPT_ID,
    isStaff ? environments.feedbackAssistSourceUrl : null,
    FEEDBACK_ASSIST_INLINE_SCRIPT_ID,
    `caBoootstrap.init("${environments.feedbackAssistFooterSourceUrl}");`,
    true,
    () => console.log('Feedback Assist script loaded successfully'),
    () => console.error('Failed to load Feedback Assist script'),
  );

  return null;
};

export default FeedbackAssist;
