import { VALID_FILE_FORMATS } from 'src/constants';

export const getValidInvalidFilesArray = (uploadedFiles: File[]) => {
  const { validFiles, invalidFiles } = uploadedFiles.reduce(
    (acc, file) => {
      if (file.size !== 0) {
        const isExtensionAllowed = ['.msg', '.rar', '.7z'].some((ext) =>
          file.name.endsWith(ext),
        );
        if (VALID_FILE_FORMATS.includes(file.type) || isExtensionAllowed) {
          acc.validFiles.push(file);
        } else {
          acc.invalidFiles.push(file);
        }
      } else {
        acc.invalidFiles.push(file);
      }
      return acc;
    },
    { validFiles: [] as File[], invalidFiles: [] as File[] },
  );

  return { validFiles, invalidFiles };
};
