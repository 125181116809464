import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  ContentContainer,
  Heading,
  Row,
  StatusLabel,
} from '@snsw/react-component-library';
import { Breadcrumbs, HeadingItem, LabelDescriptionList } from 'ams-common';
import html2canvas from 'html2canvas';
import { QueryKey } from 'src/api/constants';
import { ArchiveToTrim } from 'src/components/archiveToTrim';
import { TrimUploadSuccessful } from 'src/components/archiveToTrim/TrimUploadSuccessful';
import { useClients, useMatterClients } from 'src/components/clients/hooks';
import { DocumentsSection } from 'src/components/DocumentsSection';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useCurrentContactDetails, useUserContext } from 'src/hooks';
import { useOnboarding } from 'src/hooks/contact/hooks';
import {
  useMatterCorrespondenceResponses,
  useMatterCorrespondences,
  useMatterCorrespondenceUploadToTrim,
  useMatterThreadDetails,
} from 'src/hooks/correspondence/hooks';
import {
  MatterCorrespondenceDetailResponse,
  MatterCorrespondenceUploadToTrimData,
  MatterCorrespondenceUploadToTrimResponse,
} from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import { SubNavigationTitle } from 'src/screens/common/matters/types';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';
import getContent, { combineContent } from 'src/utils/contentUtils';
import { createPDF } from 'src/utils/trimPdfUtils';

import { CloseCorrespondenceModal } from '../CloseCorrespondenceModal';
import {
  CLOSE_CORRESPONDENCE_STATUS,
  CorroTypes,
  MessageType,
  PDF_EXT,
} from '../constants';
import {
  getContentKeyFromCorrespondenceKey,
  getPathFromCorrespondenceKey,
  handleCloseCorrespondence,
} from '../helper';
import { useCloseCorrespondence } from '../hooks';

import {
  getAuditCommencementSections,
  getContactIds,
  getDraftResponses,
  getMatterCorrespondenceDetails,
} from './helpers';
import {
  BackButton,
  ButtonWrapper,
  CloseCorroButton,
  DocumentLinksCard,
  MessageWrapper,
  StyledContainer,
  StyledMessageBody,
  StyledRow,
  StyledStatusLabelContainer,
} from './styles';

export const CorrespondenceDetails = () => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const [showArchiveToTrimModal, setShowArchiveToTrimModal] = useState(false);
  const [showCloseCorroModal, setShowCloseCorroModal] = useState(false);
  const [corroType, setCorroType] = useState('');
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [trimRecord, setTrimRecord] =
    useState<MatterCorrespondenceUploadToTrimData | null>(null);

  const navigate = useNavigate();
  const { threadId = '' } = useParams();
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const {
    matterId,
    matterUId,
    isMatterClosed,
    updateMatterCorrespondenceThreadId,
    matterLeadClientId,
  } = useMatter();
  const { clientOptions, isLoading: isClientsLoading } = useClients(
    matterUId,
    isCustomer,
  );
  const { data: matterClients, isLoading: matterClientsLoading } =
    useMatterClients(matterUId);
  const {
    data: matterCorrespondenceResponses,
    isLoading: isMatterCorrespondenceResponsesLoading,
    isError: isMatterCorrespondenceResponsesError,
  } = useMatterCorrespondenceResponses(isCustomer, matterUId, threadId);

  const {
    data: matterCorrespondence,
    isLoading: isMatterCorrespondencesLoading,
  } = useMatterCorrespondences(isCustomer, matterUId);

  const { data: onboardingData, isLoading: isOnboardingDataLoading } =
    useOnboarding(matterUId, isCustomer);

  const navigateSuccess = () => {
    navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`, {
      state: {
        messageType: MessageType.Success,
        message: `Correspondence ${corroType} has been closed.`,
      },
    });
  };

  useEffect(() => {
    if (isMatterCorrespondenceResponsesError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CORRESPONDENCE_DETAILS}-${QueryKey.MATTER_THREAD_DETAILS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isMatterCorrespondenceResponsesError]);

  const navigateError = () => {
    navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`, {
      state: {
        messageType: MessageType.Error,
        message: `Internal server error. Please try again after sometime.`,
      },
    });
  };

  const {
    mutate: closeCorrespondence,
    isLoading: isCloseCorrespondenceLoading,
  } = useCloseCorrespondence(matterUId, navigateError, navigateSuccess);

  const correspondenceVals = useMemo(() => {
    return matterCorrespondence?.find((item) => item.threadId === threadId);
  }, [matterCorrespondence, threadId]);

  const {
    data: currentContactDetails,
    isLoading: isCurrentContactDetailsLoading,
  } = useCurrentContactDetails(isCustomer);

  const { data: threadDetails, isLoading: threadDetailsLoading } =
    useMatterThreadDetails(isCustomer, matterUId, threadId);

  useEffect(() => {
    updateMatterCorrespondenceThreadId(threadId);
  }, [threadId, updateMatterCorrespondenceThreadId]);

  const navigateCorrespondenceUploadToTrimSuccess = (
    response: MatterCorrespondenceUploadToTrimResponse,
  ) => {
    setTrimRecord(response.data);
  };

  const navigateCorrespondenceUploadToTrimError = () => {
    setErrorKeys((prevErrorKeys) => [
      ...new Set([
        ...prevErrorKeys,
        `${ScreenNames.CORRESPONDENCE_DETAILS}-${QueryKey.MATTER_THREAD_DETAILS}-POST` as ErrorKey,
      ]),
    ]);
  };

  const { mutate, isLoading: isCorrespondenceUploadingToTrim } =
    useMatterCorrespondenceUploadToTrim(
      matterUId,
      threadId,
      navigateCorrespondenceUploadToTrimSuccess,
      navigateCorrespondenceUploadToTrimError,
    );

  const sections = getAuditCommencementSections(
    onboardingData,
    matterCorrespondenceResponses,
    matterClients,
  );
  const draftResponses = getDraftResponses(matterCorrespondenceResponses);

  const correspondenceStatus = correspondenceVals?.threadStatus || '';

  const getMainButtonDetails = (
    isCustomer: boolean,
    draftResponses: MatterCorrespondenceDetailResponse[] | null,
    submittedResponses: number,
    matterId: string | null,
    correspondenceCorroType: string,
    threadId: string | null,
  ) => {
    const draftResponsesCount = draftResponses ? draftResponses.length : 0;
    const getCorrespondenceResponseButtonDetails = () => {
      if (draftResponsesCount > 0 && draftResponses) {
        return {
          content: 'correspondence.details.button.resumeDraftResponse',
          link: `/matters/${matterId}${PATHS.threads}/${threadId}${
            PATHS.correspondence
          }/${draftResponses[0].id}/${
            getPathFromCorrespondenceKey(correspondenceCorroType) || ''
          }`,
        };
      }
      if (submittedResponses === 1) {
        return {
          content: 'correspondence.details.button.respond',
          link: `/matters/${matterId}${PATHS.threads}/${
            getPathFromCorrespondenceKey(correspondenceCorroType) || ''
          }`,
        };
      }
      return {
        content: 'correspondence.details.button.reply',
        link: `/matters/${matterId}${PATHS.threads}/${
          getPathFromCorrespondenceKey(correspondenceCorroType) || ''
        }`,
      };
    };

    if (isCustomer) {
      const { content, link } = getCorrespondenceResponseButtonDetails();
      return {
        text: getContent(content as keyof typeof combineContent),
        link,
      };
    }
    return {
      text: getContent('correspondence.details.button.reply'),
      link: `/matters/${matterId}${PATHS.threads}${
        // eslint-disable-next-line no-nested-ternary
        correspondenceCorroType === CorroTypes.AuditCommencement
          ? PATHS.respondToAuditCommencementCorrespondence
          : `/${getPathFromCorrespondenceKey(correspondenceCorroType)}` || ''
      }`,
    };
  };

  const matterCorrespondenceDetail = matterCorrespondenceResponses
    ? matterCorrespondenceResponses.find(
        ({ threadId: correspondenceThreadId }) =>
          correspondenceThreadId === threadId,
      )
    : null;

  const subNavigationTitle = matterCorrespondenceDetail
    ? (`threads.${getContentKeyFromCorrespondenceKey(
        matterCorrespondenceDetail.corroType,
      )}` as SubNavigationTitle)
    : null;

  const headingKey = matterCorrespondenceDetail
    ? getContentKeyFromCorrespondenceKey(matterCorrespondenceDetail.corroType)
    : undefined;

  const toggleButtonsVisibility = (isVisible: boolean): void => {
    const buttons = document.querySelectorAll('.hide-in-pdf');
    buttons.forEach((button) => {
      (button as HTMLElement).style.visibility = isVisible
        ? 'visible'
        : 'hidden';
    });
  };

  const generatePDF = async () => {
    const input = pdfRef.current;
    if (!input) return;

    toggleButtonsVisibility(false);

    try {
      const canvas = await html2canvas(input, {
        useCORS: true,
        allowTaint: true,
        scrollY: 0,
      });

      const pdf = await createPDF(canvas);
      const pdfBlob = pdf.output('blob');
      const pdfFile = new File([pdfBlob], 'document.pdf', {
        type: 'application/pdf',
      });

      // eslint-disable-next-line consistent-return
      return pdfFile;
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      toggleButtonsVisibility(true);
    }
  };

  const handleTrimUpload = async ({
    entity,
    trimTitle,
    description,
  }: {
    entity: string;
    description: string;
    trimTitle: string;
  }) => {
    setShowArchiveToTrimModal(false);
    const pdf = await generatePDF();
    if (pdf) {
      mutate({
        file: pdf,
        fileName: `${trimTitle}${PDF_EXT}`,
        clientId: Number(entity),
        trimRecordTitle: trimTitle,
        trimDescription: description,
      });
    }
  };

  return (
    <>
      <ComponentLoader
        active={
          isMatterCorrespondenceResponsesLoading ||
          isCloseCorrespondenceLoading ||
          isMatterCorrespondencesLoading ||
          isCurrentContactDetailsLoading ||
          isClientsLoading ||
          isCorrespondenceUploadingToTrim ||
          threadDetailsLoading ||
          matterClientsLoading ||
          isOnboardingDataLoading
        }
        fullPage
      />
      <ContentContainer>
        {!isCustomer && subNavigationTitle ? (
          <Breadcrumbs
            paths={getMattersBreadcrumbs(
              'threads',
              subNavigationTitle,
              matterId,
            )}
            isAccessible={!isCustomer}
          />
        ) : null}

        {!isCustomer &&
          !isMatterClosed &&
          correspondenceStatus !== CLOSE_CORRESPONDENCE_STATUS && (
            <CloseCorroButton
              variant="secondary"
              onClick={() => {
                setCorroType(correspondenceVals?.corroType || '');
                setShowCloseCorroModal(true);
              }}
            >
              Close correspondence
            </CloseCorroButton>
          )}

        <CloseCorrespondenceModal
          open={showCloseCorroModal}
          handleCloseCorrespondence={() =>
            handleCloseCorrespondence(
              threadId || '',
              setShowCloseCorroModal,
              closeCorrespondence,
            )
          }
          close={() => setShowCloseCorroModal(false)}
        />

        {correspondenceStatus === CLOSE_CORRESPONDENCE_STATUS ? (
          <StyledStatusLabelContainer>
            <Row>
              <Col>
                <StatusLabel
                  text={getContent('correspondence.close.status.label.closed')}
                  variant="error"
                />
              </Col>
            </Row>
          </StyledStatusLabelContainer>
        ) : (
          ''
        )}
        <div ref={pdfRef}>
          <Row>
            <Col>
              <Heading level={3}>
                {headingKey
                  ? getContent(
                      `matter.correspondence.${headingKey}.heading` as keyof typeof combineContent,
                    )
                  : null}
              </Heading>
            </Col>
          </Row>
          <ErrorHandler keys={errorKeys} />
          <StyledContainer>
            {sections && matterCorrespondenceResponses
              ? Object.keys(sections).map((id, index) => {
                  const section = sections[id];
                  const isFirstItem = index === 0;
                  const submittedCorrespondences = Object.keys(sections).length;
                  const matterCorroDetails = getMatterCorrespondenceDetails(
                    matterCorrespondenceResponses,
                    id,
                  );

                  const { corroType: correspondenceCorroType = '' } =
                    matterCorroDetails || {};

                  const mainButtonDetails = getMainButtonDetails(
                    isCustomer,
                    draftResponses,
                    submittedCorrespondences,
                    matterId,
                    correspondenceCorroType,
                    threadId,
                  );

                  const currentPortalContactIds =
                    getContactIds(matterCorrespondenceResponses) ?? [];
                  const shouldPrimaryButtonEnabled = isCustomer
                    ? !!(
                        currentContactDetails?.contactId &&
                        currentPortalContactIds?.includes(
                          currentContactDetails.contactId,
                        )
                      )
                    : true;
                  const { listItems, documents, messageBody } = section;

                  return (
                    <>
                      {matterCorroDetails?.response ? (
                        <Row>
                          <Col>
                            <StatusLabel text="Response" variant="info" />
                          </Col>
                        </Row>
                      ) : null}
                      <StyledRow key={id}>
                        <Col>
                          <>
                            <LabelDescriptionList list={listItems} />
                            <MessageWrapper>
                              <HeadingItem>
                                {getContent(
                                  'correspondence.details.label.message',
                                )}
                              </HeadingItem>
                              <StyledMessageBody>
                                {messageBody?.split('\n').map((line) => (
                                  <>
                                    <p key={`line-${line}`}>{line}</p>{' '}
                                    {!line ? <br /> : null}
                                  </>
                                ))}
                              </StyledMessageBody>
                            </MessageWrapper>
                            {documents?.length ? (
                              <DocumentLinksCard>
                                <h4>Documents</h4>
                                <DocumentsSection documents={documents} />
                              </DocumentLinksCard>
                            ) : null}
                            {isFirstItem ? (
                              <ButtonWrapper>
                                <Link
                                  to={`/matters/${matterId}${PATHS.threads}`}
                                  className="hide-in-pdf"
                                >
                                  <BackButton variant="secondary">
                                    {getContent(
                                      draftResponses &&
                                        draftResponses?.length > 0
                                        ? 'correspondence.details.button.cancel'
                                        : 'correspondence.details.button.back',
                                    )}
                                  </BackButton>
                                </Link>
                                {shouldPrimaryButtonEnabled
                                  ? correspondenceStatus &&
                                    !isMatterClosed &&
                                    correspondenceStatus !==
                                      CLOSE_CORRESPONDENCE_STATUS && (
                                      <Link to={mainButtonDetails.link}>
                                        <Button>
                                          {mainButtonDetails.text}
                                        </Button>
                                      </Link>
                                    )
                                  : null}
                                {!isCustomer &&
                                correspondenceStatus &&
                                correspondenceStatus ===
                                  CLOSE_CORRESPONDENCE_STATUS &&
                                !threadDetails?.trimRecordNumber ? (
                                  <Button
                                    variant="primary"
                                    onClick={async () => {
                                      setShowArchiveToTrimModal(true);
                                    }}
                                    className="hide-in-pdf"
                                  >
                                    Upload to TRIM
                                  </Button>
                                ) : null}
                              </ButtonWrapper>
                            ) : null}
                          </>
                        </Col>
                      </StyledRow>
                    </>
                  );
                })
              : null}
          </StyledContainer>
        </div>
      </ContentContainer>
      <ArchiveToTrim
        open={showArchiveToTrimModal}
        close={() => setShowArchiveToTrimModal(false)}
        clientOptions={clientOptions}
        matterLeadClientId={matterLeadClientId}
        correspondenceTrimTitleSegment={getContent(
          `matter.correspondence.${headingKey}.heading` as keyof typeof combineContent,
        )}
        modalType="correspondence"
        onUpload={handleTrimUpload}
      />
      {trimRecord ? (
        <TrimUploadSuccessful
          open={!!trimRecord}
          close={() => {
            setTrimRecord(null);
            navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`);
          }}
          record={trimRecord}
          modalType="correspondence"
        />
      ) : null}
    </>
  );
};
