const contents = {
  'matter.correspondence.submitAuditCommencementDocuments.heading':
    'Audit Commencement Documents',
  'matter.correspondence.submitAuditCommencementDocuments.first.sub.heading':
    'Details',
  'matter.correspondence.submitAuditCommencementDocuments.second.sub.heading':
    'Documents',
  'matters.correspondence.submitAuditCommencementDocuments.heading.text':
    'Submit Audit Commencement Documents',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.title':
    'Confirm Audit Commencement Documents',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.text':
    'Confirm you want to send the following:',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.customer.label':
    'Entity',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.recipient.label':
    'Recipient name',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.dueDate.label':
    'Due date',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.document.header':
    'Documents',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.messageSubject.header':
    'Message Subject',
  'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.message.header':
    'Message',
  'matters.correspondence.submitAuditCommencementDocuments.modal.draft.title':
    'Save as draft',
  'matters.correspondence.submitAuditCommencementDocuments.modal.draft.content':
    'A draft will be saved in correspondence',
  'matters.correspondence.submitAuditCommencementDocuments.dueDate.tooltip.text':
    'This is the NOI due date from the AMS system',
  'matters.correspondence.customer.tooltip.text':
    'This is the customer for which this correspondence relates to',
  'matters.correspondence.message.helpMessage.text':
    'Maximum 1000 characters including spaces. {remChar} character(s) remaining',
  'matters.correspondence.message.text':
    'The audit commencement documents have been uploaded for your review and action. Please complete the Payroll Tax Questionnaire and return this along with the relevant documentation requested (refer to the ‘Documentation’ sheet), by uploading your submissions in the Documents section. Feel free to contact me through the portal should you need any help during this investigation.',
};

export default contents;
