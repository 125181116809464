import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle, theme } from '@snsw/react-component-library';
import { ErrorBoundary } from 'ams-common';
import { ThemeProvider } from 'styled-components';

import AppWithRouter from './routes/AppWithRouter';
import environments from './environments';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {},
    queries: {
      staleTime: 3600000, // 1 h
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme} data-styled-components>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={environments.baseName}>
          <ErrorBoundary>
            <AppWithRouter />
          </ErrorBoundary>
        </BrowserRouter>
        {!environments.disableDevTools ? <ReactQueryDevtools /> : null}
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
