import { FormGroup, Input } from '@snsw/react-component-library';
import { MultiSelect, Tooltip } from 'ams-common';
import { ClientOptions } from 'src/components/clients/types';
import getContent from 'src/utils/contentUtils';

interface EntityNamesProps {
  label?: string;
  clientOptions: ClientOptions;
  hasError: boolean;
  errorMessage?: string;
  value: string;
  handleChange: ({ value, field }: { value: string; field: string }) => void;
  isCustomer: boolean;
}

export const EntityNames = ({
  label = 'Correspondence relates to',
  isCustomer,
  clientOptions,
  value,
  hasError,
  errorMessage,
  handleChange,
}: EntityNamesProps) => {
  if (isCustomer) {
    return null;
  }

  return (
    <FormGroup
      id="customer"
      label={
        <Tooltip
          label={label}
          text={getContent('matters.correspondence.customer.tooltip.text')}
        />
      }
      errorMessage={errorMessage}
      hasError={hasError}
    >
      {clientOptions && clientOptions.length > 1 ? (
        <MultiSelect
          inputWidth="xl"
          name="entities"
          value={value}
          showSelectAll
          options={clientOptions}
          onChange={(val: string) =>
            handleChange({ value: val, field: 'entities' })
          }
          selectAllLabel="Select all entity names"
          singleSelectionLabel="entity"
          multipleSelectionLabel="entities"
        />
      ) : (
        <Input
          name="entities"
          value={
            clientOptions &&
            `${clientOptions[0].value} ${clientOptions[0].text}`
          }
          inputWidth="xl"
          placeholder="Please enter entity"
        />
      )}
    </FormGroup>
  );
};
