import {
  Button,
  Col,
  Heading,
  TableCell,
  TableContainer,
  tokens,
} from '@snsw/react-component-library';
import { colours, fontSizes, fontWeight, pxToRem, spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  h3 {
    margin-top: ${pxToRem(0)};
    margin-bottom: ${spacing.lg};
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${spacing.sm};
`;

export const StyledTableHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
`;

export const StyledIcon = styled.span`
  width: ${pxToRem(47)};
  height: ${pxToRem(24)};
  background-color: ${colours.backgrounds.activeLight};
  color: white;
  font-weight: ${tokens.font.weight.medium};
  border-radius: ${pxToRem(40)} ${pxToRem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${spacing.xs};
  padding: ${spacing.sm};
`;
export const StyledIconWithNumContainer = styled.div`
  display: flex;
`;

export const StyledTooltipText = styled.div<{ width: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.width ? pxToRem(props.width) : pxToRem(150))};
`;

export const StyledTableCell = styled(TableCell)<{
  isRead: boolean;
  isSubject: boolean;
}>`
  ${(props) =>
    !props.isRead &&
    `    
       background-color: ${colours.backgrounds.info};
    `}
  ${(props) =>
    props.isSubject &&
    `
      width: 40%;
    `}
`;

export const StyledTableContainer = styled(TableContainer)`
  div:first-of-type {
    overflow-x: hidden;
  }
`;

export const StyledHoverText = styled.span`
  display: none;
  position: absolute;
  padding: ${spacing.sm};
  align-items: center;
  color: white;
  background: black;
  transform: translateX(-10%) translateY(-110%);
  width: max-content;
  max-width: ${pxToRem(400)};

  ${StyledTableCell}:hover & {
    display: block;

    &:after {
      border: ${spacing.xs} solid transparent;
      border-top-color: black;
      content: ' ';
      position: absolute;
      left: 20%;
      transform: translateX(-20%) translateY(250%);
      z-index: 100;
    }
  }
`;

export const StyledListContainer = styled.div`
  border-top: 2px solid #dee3e5;
  padding: ${spacing.lg} 0;
  h4.message-body {
    margin-bottom: 10px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0px;
  margin-bottom: 0.5rem;

  padding-bottom: 32px;
  span {
    font-weight: ${tokens.font.weight.book};
    font-size: ${pxToRem(18)};
  }
`;

export const StyledMessageLabel = styled.div`
  font-size: ${fontSizes.md};
  font-weight: ${fontWeight.bold};
`;

export const StyledMessageHeading = styled.div`
  font-size: ${fontSizes.lg};
  font-weight: ${fontWeight.bold};
`;

export const StyledRecipients = styled.div`
  font-weight: ${fontWeight.bold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  button {
    margin-right: 20px;
  }
`;

export const StyledMessageContainer = styled.div`
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
  color: ${colours.text.disabled};
`;

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;

export const DocumentLinksCard = styled.div`
  background-color: ${colours.backgrounds.selected};
  padding: ${spacing.sm};
  width: 60%;
`;

export const StyledTrimUploadMessageCol = styled(Col)`
  display: flex;
  margin-bottom: ${spacing.sm};
`;

export const StyledMessageCol = styled(Col)`
  display: flex;
  flex-direction: row;
  gap: ${spacing.md};
  align-items: center;
  margin-bottom: ${spacing.lg};
`;

export const StyledMessageReply = styled(Col)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.sm};
  align-items: center;
`;
export const StyledMessageReplyCol = styled(Col)`
  display: flex;
  flex-direction: row;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.md};
`;
export const StyledMessageReplyText = styled.div`
  margin-bottom: ${spacing.md};
  color: ${colours.text.disabled};
  padding-left: ${pxToRem(12)};
`;

export const StyledMessageDetails = styled.div`
  margin-top: ${spacing.lg};
`;

export const StyledMessageSubject = styled.span`
  padding-left: ${spacing.sm};
`;
