const content = {
  'matters.actionItemCard.title.text': 'Action items',
  'matters.actionItemCard.dueDate.text': 'Due ',
  'matters.actionItemCard.respond.text': 'Respond to ',
  'matters.actionItemCard.initiatedBy.text': 'Initiated by ',
  'matters.actionItemTable.title.text': 'Action items',
  'matters.actionItemTable.label.text': 'Filter by audit matter',
  'matters.actionItemTable.subtitle.text': 'action item(s)',
  'matters.actionItemTable.header.actionItem.text': 'Action item',
  'matters.actionItemTable.header.dueDate.text': 'Due date',
  'matters.actionItemTable.matterId.label.text': 'Matter',
  'matters.actionItemTable.actionItem.prefix.text': 'Review ',
  'matters.actionItemTable.actionItem.suffix.text': ' response received ',
  'actionItems.dueDate.modal.title': '{action} due date',
  'actionItems.dueDate.modal.text':
    '{action} the action items response due date',
  'actionItems.dueDate.modal.form.field1.title': 'Due date',
  'actionItems.dueDate.modal.form.field2.title': 'Reason',
  'actionItems.dueDate.modal.dueDate.tooltip.text':
    'This is the due date by when auditor is expected to respond',
  'actionItems.dueDate.modal.secondary.button': '{action}',
  'actionItems.dueDate.modal.primary.button': 'Cancel',
  'actionItems.dueDate.confirmModal.secondary.button': 'Confirm',
  'actionItems.dueDate.confirmModal.text':
    'Please confirm you want to change the due date {oldDate} to {newDate}',
};

export default content;
