import { formatDate, MatterAlert } from 'ams-common';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { AuditorOOOData } from './types';

type ExtendedAuditorOOOData = AuditorOOOData & {
  matterId: string;
};

type AuditorOOOProps = {
  data: ExtendedAuditorOOOData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string) => void;
  alertId: string;
};

export const AuditorOOO = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: AuditorOOOProps) => {
  const { matterId, auditorName, toDate, fromDate, outOfOfficeMessage } = data;

  const title = getContentWithReplacements(
    'matterAlert.alert.auditor.ooo.title',
    {
      auditorName,
      from: formatDate(fromDate, 'DD MMM YYYY'),
      to: formatDate(toDate, 'DD MMM YYYY'),
    },
  );

  const body = getContentWithReplacements(
    'matterAlert.alert.auditor.ooo.body',
    {
      oooMessage: outOfOfficeMessage,
    },
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`/matters/${matterId}/contacts`}
      linkText="View contacts"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId);
      }}
    />
  );
};
