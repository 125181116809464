import {
  Col,
  ContentContainer,
  Heading,
  Row,
} from '@snsw/react-component-library';
import { Breadcrumbs } from 'ams-common';
import { useUserContext } from 'src/hooks';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';

import { InternalMatterNotifications } from './auditor';
import { ExternalMatterNotifications } from './customer';

export const MatterNotifications = () => {
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;

  return (
    <ContentContainer>
      <Breadcrumbs
        paths={getMattersBreadcrumbs('matterNotifications')}
        isAccessible={!isCustomer}
      />
      <Row>
        <Col>
          <Heading level={3} />
        </Col>
      </Row>

      {isCustomer ? (
        <ExternalMatterNotifications />
      ) : (
        <InternalMatterNotifications />
      )}
    </ContentContainer>
  );
};
