import { formatDate, ListItemType } from 'ams-common';
import { Clients } from 'src/components/clients/types';
import {
  Documents,
  MatterCorrespondenceDetailResponses,
  OnboardingData,
  PortalContacts,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import { getCorrespondenceDetailsClientNames } from '../helper';

import { ReplyFrom } from './types';

const getContactNames = (portalContacts?: PortalContacts) => {
  if (!portalContacts) {
    return [];
  }

  return portalContacts.map(({ contactName }) => contactName);
};

export const getUniqueEntityNames = (portalContacts: PortalContacts) =>
  Array.from(new Set(portalContacts?.map((item) => item.clientName)));

export const getClientName = (
  onboardingData?: OnboardingData | null,
  portalContactId?: string | null,
) => {
  if (!onboardingData || !portalContactId) {
    return '';
  }

  const onboardingContacts = onboardingData.flatMap((client) =>
    client.contacts.map((contact) => ({
      recipientName: contact.name || '',
      recipientId: `${contact.id}`,
      clientId: client.id,
      clientName: client.name,
    })),
  );

  const filteredOnboardedRecipients = onboardingContacts.filter(
    (contact) => contact.recipientId === portalContactId,
  )[0];

  return filteredOnboardedRecipients && filteredOnboardedRecipients.clientName;
};

export const getAuditCommencementSections = (
  onboardingData?: OnboardingData,
  data?: MatterCorrespondenceDetailResponses,
  clients?: Clients,
) => {
  if (!data) {
    return null;
  }
  return data.reduce(
    (acc, correspondence, index) => {
      const {
        id,
        sentDate,
        portalContacts,
        dueDate,
        subject,
        documents,
        messageBody,
        replyFrom,
        sentBy,
        auditor,
        clients: correspondenceClients,
      } = correspondence;
      if (sentDate) {
        let sendByText = sentBy.name;
        if (replyFrom === ReplyFrom.CUSTOMER) {
          const associatedClientName = getClientName(
            onboardingData,
            sentBy.portalContactId,
          );
          if (associatedClientName) {
            // if contact has been deleted, we can't find the associated client
            // therefore we don't display the `form xxx`. see AMSP2-1831
            sendByText = `${sendByText} from ${associatedClientName}`;
          }
        }
        acc[id] = {
          listItems: [
            {
              label: getContent('correspondence.details.label.sentOn'),
              description: formatDate(sentDate, 'DD MMMM YYYY HH:mm'),
            },
            {
              label: getContent('correspondence.details.label.sentBy'),
              description: sendByText,
            },
            ...(replyFrom === ReplyFrom.CUSTOMER
              ? [
                  {
                    label: getContent('correspondence.details.label.recipient'),
                    description: auditor,
                  },
                ]
              : [
                  {
                    label: getContent(
                      'correspondence.details.label.entityName',
                    ),
                    description: portalContacts
                      ? getCorrespondenceDetailsClientNames(
                          clients,
                          correspondenceClients,
                        ).join(', ')
                      : '',
                  },
                  {
                    label: getContent(
                      'correspondence.details.label.recipients',
                    ),
                    description: getContactNames(portalContacts).join(', '),
                  },
                  ...(index === data.length - 1
                    ? [
                        {
                          label: getContent(
                            'correspondence.details.label.dueDate',
                          ),
                          description: formatDate(dueDate, 'DD MMMM YYYY'),
                        },
                      ]
                    : []),
                ]),
            {
              label: getContent('correspondence.details.label.subject'),
              description: subject,
            },
          ],
          documents,
          messageBody,
        };
      }
      return acc;
    },
    {} as {
      [id: string]: {
        listItems: ListItemType[];
        documents: Documents;
        messageBody: string | undefined | null;
      };
    },
  );
};

export const getDocumentIds = (documents: Documents) => {
  return documents?.map(({ id }) => id) || [];
};

export const getMatterCorrespondenceDetails = (
  data: MatterCorrespondenceDetailResponses,
  id: string,
) => {
  return data.find(({ id: corroId }) => corroId === id);
};

export const getDraftResponses = (
  data?: MatterCorrespondenceDetailResponses,
) => {
  if (!data) {
    return null;
  }

  return data.filter(
    ({ corroStatus, replyFrom }) =>
      corroStatus === 'Draft' && replyFrom === 'CUSTOMER',
  );
};

export const getContactIds = (data?: MatterCorrespondenceDetailResponses) => {
  if (!data) {
    return null;
  }
  // Filter only auditor responses, get the latest response and return contactIds
  const filteredData = data.filter((da) => da.replyFrom !== 'CUSTOMER');
  const correspondenceDetails = filteredData[0];
  if (correspondenceDetails) {
    return (
      correspondenceDetails.portalContacts?.map(({ contactId }) => contactId) ||
      null
    );
  }
  return null;
};
