import { EllipsisText, formatDate } from 'ams-common';
import {
  ActionRowButton,
  ActionRowLink,
} from 'src/components/buttonWithPopup/styles';

import { ButtonWithPopup } from '../buttonWithPopup';
import { NoStyleButton } from '../correspondence/styles';

import {
  DISPLAY_STATUS_MAPPER,
  DocumentFieldLabels,
  DocumentFieldNames,
} from './constants';
import { DocumentCheckbox } from './documentCheckbox';
import { StyledCell, StyledDescriptionPopup } from './styles';
import { ColumnType } from './types';

export const columns = (): ColumnType[] => [
  {
    header: DocumentFieldLabels.DocumentName,
    dataIndex: DocumentFieldNames.DocumentName,
    render: ({ columnData, rowData, handleDownloadDocument }) => {
      return (
        <StyledCell style={{ whiteSpace: 'pre-wrap' }}>
          {/* <Indicator isRead={rowData[DocumentFieldNames.IsRead]} />
          <IconProfile title="Chat with us" /> */}
          <DocumentCheckbox
            id={rowData[DocumentFieldNames.DocumentId]}
            name={rowData[DocumentFieldNames.DocumentId]}
            type="checkbox"
            isDisabled={!rowData[DocumentFieldNames.DocumentS3Key]}
            label={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              rowData[DocumentFieldNames.DocumentS3Key] ? (
                <NoStyleButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownloadDocument?.(
                      rowData[DocumentFieldNames.DocumentId],
                      `${columnData}`,
                    );
                  }}
                >
                  {columnData}
                </NoStyleButton>
              ) : (
                <span>{columnData}</span>
              )
            }
            // value={rowData[DocumentFieldNames.IsSelected]}
          />
        </StyledCell>
      );
    },
  },
  {
    header: DocumentFieldLabels.Status,
    dataIndex: DocumentFieldNames.DisplayStatus,
    render: ({ columnData }) => DISPLAY_STATUS_MAPPER[columnData as string],
  },
  {
    header: DocumentFieldLabels.UploadedDate,
    dataIndex: DocumentFieldNames.UploadedDate,
    render: ({ columnData }) => formatDate(columnData as string),
  },
  {
    header: DocumentFieldLabels.UploadedBy,
    dataIndex: DocumentFieldNames.UploadedBy,
  },
  {
    header: DocumentFieldLabels.Classification,
    dataIndex: DocumentFieldNames.Classification,
  },
  {
    header: DocumentFieldLabels.Description,
    dataIndex: DocumentFieldNames.Description,
    // render: ({ columnData }) => <EllipsisText>{columnData}</EllipsisText>,
    render: ({ columnData }) => {
      return columnData && columnData.toString().length > 21 ? (
        <ButtonWithPopup
          renderActionItems={() => (
            <StyledDescriptionPopup>{columnData}</StyledDescriptionPopup>
          )}
          title={columnData}
        />
      ) : (
        <EllipsisText>{columnData}</EllipsisText>
      );
    },
  },
  {
    header: '',
    dataIndex: DocumentFieldNames.Action,
    render: ({
      rowData,
      handleDownloadDocument,
      handleDocumentEdit,
      handleDeleteDocument,
    }) =>
      rowData[DocumentFieldNames.DocumentS3Key] && (
        <ButtonWithPopup
          renderActionItems={({ handleClosePopup }) => (
            <>
              <ActionRowButton
                type="button"
                onClick={() => {
                  const {
                    [DocumentFieldNames.DocumentName]: documentName,
                    [DocumentFieldNames.DocumentId]: documentId,
                    [DocumentFieldNames.ClientId]: clientId,
                    [DocumentFieldNames.Classification]: classification,
                    [DocumentFieldNames.Description]: description,
                  } = rowData;
                  handleDocumentEdit?.({
                    documentName,
                    documentId,
                    clientId,
                    classification,
                    description,
                  });
                  handleClosePopup();
                }}
              >
                Edit Details
              </ActionRowButton>
              <ActionRowLink
                href="#"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  handleDownloadDocument?.(
                    rowData[DocumentFieldNames.DocumentId],
                    rowData[DocumentFieldNames.DocumentName],
                  );
                  handleClosePopup();
                }}
              >
                Download Document
              </ActionRowLink>
              <ActionRowButton
                type="button"
                onClick={() => {
                  handleDeleteDocument?.(
                    rowData[DocumentFieldNames.DocumentId],
                    rowData[DocumentFieldNames.DocumentName],
                  );
                  handleClosePopup();
                }}
              >
                Remove Document
              </ActionRowButton>
            </>
          )}
        />
      ),
  },
];
